/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// dynamic injecting imports into IIFE will break some legacy
// script files imports (wich could be function, and injecting imports
// inside a function throws errors.)

// GLOBAL POLYFILLS
import 'global/polyfills';
import 'global'; // app-wide stylesheets and javascripts

// GLOBAL STYLES
import 'global/stylesheets/vendor.scss';
import 'global/stylesheets/core.scss';

// COMPONENTS
import 'components/signup/signup';
import 'components/callout/callout';
import 'components/pricing/pricing';
import 'components/stat/stat';
import 'components/worldmap/worldmap';
import 'components/feature-footer/component.css';

// FUNCTIONAL UTITLTY CLASSES
import 'global/stylesheets/utilities.scss';

// Add as global so it can be used in global scripts to keep perf in check
import throttle from 'lodash.throttle';

import initSentry from './sentry';
import toggleChatWidget from '../hooks/hubspot-chat-toggle';

document.addEventListener('DOMContentLoaded', () => {
  const { sentryBrowserConfig } = document.body.dataset;

  if (sentryBrowserConfig) initSentry(sentryBrowserConfig);

  // Hook up the chat widgets
  document.querySelectorAll('[data-id="open-chat-widget"]').forEach((el) => {
    toggleChatWidget({ container: el.parentElement });
  });
});

window._ = { throttle };

// making assets available in view
// eslint-disable-next-line
require.context('../assets', true, /\.(gif|jpg|jpeg|webp|png|svg|mp4)$/i);
